<template>
  <div class="wrap">
    <van-form class="form" ref='form'>
      <van-cell-group inset>
        <h1><em>*</em>基础数据</h1>
        <van-field readonly clickable label="身高" name="身高" right-icon="arrow" :value="height" placeholder="请选择" :rules="[$.rules.required]" @click="heightShowPicker = true" />

        <van-field readonly clickable label="体重" name="体重" right-icon="arrow" :value="weight" placeholder="请选择" :rules="[$.rules.required]" @click="weightShowPicker = true" />

        <van-field readonly clickable label="体质指数" name="体质指数" :value="params.bmi" />
      </van-cell-group>

      <van-cell-group inset>
        <h1><em>*</em>既往病史</h1>
        <div class="item" :class="params.disease.ownIllHistory == 1 ? '' : 'bt'">
          <van-field name="既往病史" :rules="[$.rules.required]" label="既往病史">
            <template #input>
              <van-radio-group class="radiogroup" v-model="params.disease.ownIllHistory" direction="horizontal">
                <!-- <label>既往病史</label> -->
                <div class="con">
                  <van-radio :name="0">无</van-radio>
                  <van-radio :name="1">有</van-radio>
                </div>
              </van-radio-group>
            </template>
          </van-field>

          <van-field class="textarea" v-if="params.disease.ownIllHistory == 1" v-model="params.disease.ownIllHistoryText" name="既往病史" rows="2" autosize maxlength="50" show-word-limit type="textarea"
            :rules="params.disease.ownIllHistory == 1 ? [$.rules.required] : ''" placeholder="" />
        </div>
        <div class="item" :class="params.disease.allergyHistory == 1 ? '' : 'bt'">
          <van-field name="过敏史" label="过敏史" :rules="[$.rules.required]">
            <template #input>
              <van-radio-group class="radiogroup" v-model="params.disease.allergyHistory" direction="horizontal">
                <div class="con">
                  <van-radio :name="0">无</van-radio>
                  <van-radio :name="1">有</van-radio>
                </div>
              </van-radio-group>
            </template>
          </van-field>
          <van-field class="textarea" v-if="params.disease.allergyHistory == 1" v-model="params.disease.allergyHistoryText" name="过敏史" rows="2" autosize maxlength="50"
            :rules="params.disease.allergyHistory == 1 ? [$.rules.required] : ''" show-word-limit type="textarea" placeholder="" />
        </div>
        <div class="item" :class="params.disease.familyHistory == 1 ? '' : 'bt'">
          <van-field name="家族遗传史" :rules="[$.rules.required]" label="家族遗传史">
            <template #input>
              <van-radio-group class="radiogroup" v-model="params.disease.familyHistory" direction="horizontal">

                <div class="con">
                  <van-radio :name="0">无</van-radio>
                  <van-radio :name="1">有</van-radio>
                </div>
              </van-radio-group>
            </template>
          </van-field>
          <van-field class="textarea" v-if="params.disease.familyHistory == 1" v-model="params.disease.familyHistoryText" rows="2" autosize name="家族遗传史" maxlength="50"
            :rules="params.disease.familyHistory == 1 ? [$.rules.required] : ''" show-word-limit type="textarea" placeholder="" />
        </div>
        <div class="item" :class="params.disease.liverFunction == 1 ? '' : 'bt'">
          <van-field name="肝功能异常" :rules="[$.rules.required]" label="肝功能异常">
            <template #input>
              <van-radio-group class="radiogroup" v-model="params.disease.liverFunction" direction="horizontal">

                <div class="con">
                  <van-radio :name="0">无</van-radio>
                  <van-radio :name="1">有</van-radio>
                </div>
              </van-radio-group>
            </template>
          </van-field>
          <van-field class="textarea" v-if="params.disease.liverFunction == 1" v-model="params.disease.liverFunctionText" rows="2" name="肝功能异常" autosize maxlength="50"
            :rules="params.disease.liverFunction == 1 ? [$.rules.required] : ''" show-word-limit type="textarea" placeholder="" />
        </div>
        <div class="item">
          <van-field name="肾功能异常" :rules="[$.rules.required]" label="肾功能异常">
            <template #input>
              <van-radio-group class="radiogroup" v-model="params.disease.kidneyFunction" direction="horizontal">

                <div class="con">
                  <van-radio :name="0">无</van-radio>
                  <van-radio :name="1">有</van-radio>
                </div>
              </van-radio-group>
            </template>
          </van-field>
          <van-field class="textarea" v-if="params.disease.kidneyFunction == 1" v-model="params.disease.kidneyFunctionText" rows="2" autosize name="肾功能异常" maxlength="50"
            :rules="params.disease.kidneyFunction == 1 ? [$.rules.required] : ''" show-word-limit type="textarea" placeholder="" />
        </div>

      </van-cell-group>

      <van-cell-group inset>
        <h1><em>*</em>确诊疾病</h1>
        <div class="disease" v-for="(item,i) in params.diagnoses" :key="i">
          <div>
            <van-field readonly clickable :label="`确诊日期${i + 1}`" name="确诊日期" right-icon="arrow" :value="params.diagnoses[i].diagnoseDate"
              placeholder="请选择" :rules="[$.rules.required]" @click="handleDiagnoseDatePicker(i)" />

            <van-field :label="`临床诊断${i + 1}`" rows="1" autosize type="textarea" name="临床诊断" placeholder="请输入" v-model="params.diagnoses[i].diagnose" :rules="[$.rules.required]" @input="inputdiagnose"
              @focus="handleFocus(i)" @blur="handleBlur" />
            <van-icon v-if="i !== 0" class="remove" name="cross" @click="remove(i)" />
          </div>
        </div>

        <div class="add" @click="add" v-if="params.diagnoses.length < 5">
          <van-icon name="plus" />增加确诊疾病
        </div>
      </van-cell-group>

      <van-cell-group inset>
        <h1><em>*</em>生活方式</h1>
        <van-field readonly clickable label="锻炼方式" name="锻炼方式" right-icon="arrow" :value="params.exerciseWay" v-model="params.exerciseWay" placeholder="请选择" :rules="[$.rules.required]"
          @click="exerciseWayShowPicker = true" />

        <van-field readonly clickable label="锻炼强度" name="锻炼强度" right-icon="arrow" :value="params.exerciseStrength" v-model="params.exerciseStrength" placeholder="请选择" :rules="[$.rules.required]"
          @click="exerciseStrengthShowPicker = true" />

        <van-field readonly clickable label="锻炼频次" name="锻炼频次" right-icon="arrow" :value="params.exerciseFrequency" v-model="params.exerciseFrequency" placeholder="请选择" :rules="[$.rules.required]"
          @click="exerciseFrequencyShowPicker = true" />

        <van-field v-model="params.exerciseTime" type="number" label="每次锻炼时间" name="每次锻炼时间" placeholder="请输入" :rules="[$.rules.required]">
          <span class="extra" slot="extra">分钟</span>
        </van-field>

        <van-field v-model="params.sleepTime" type="number" label="睡眠时长" name="睡眠时长" placeholder="请输入" :rules="[$.rules.required]">
          <span class="extra" slot="extra">小时/天</span>

        </van-field>

        <van-field readonly clickable label="睡眠状况" name="睡眠状况" right-icon="arrow" :value="params.sleepState" v-model="params.sleepState" placeholder="请选择" :rules="[$.rules.required]"
          @click="sleepStateShowPicker = true" />

        <van-field readonly clickable label="饮食习惯" name="饮食习惯" right-icon="arrow" :value="params.dietHabit" v-model="params.dietHabit" placeholder="请选择" :rules="[$.rules.required]"
          @click="dietHabitShowPicker = true" />
      </van-cell-group>

      <div class="btn-group">
        <van-button color="#1AB370" class="btn-order" @click="submitAction" round>提交</van-button>
        <!-- <van-button @click="goBack">测试</van-button> -->
      </div>
    </van-form>

    <div class="diagnoses" v-if="diagnosesShow">
      <span v-for="(item,index) in diagnosesList" :key="index" @click="select(item,index)">{{item.name}}</span>
    </div>

    <van-popup v-model="heightShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择身高" :columns="heightColumns" :default-index="159" @cancel="heightShowPicker = false" @confirm="confirmHeight" />
    </van-popup>

    <van-popup v-model="weightShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择体重" :columns="weightColumns" :default-index="59" @cancel="weightShowPicker = false" @confirm="confirmWeight" />
    </van-popup>

    <van-popup v-model="exerciseWayShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择锻炼方式" :columns="exerciseWayColumns" :default-index="0" @cancel="exerciseWayShowPicker = false" @confirm="confirmExerciseWay" />
    </van-popup>

    <van-popup v-model="exerciseStrengthShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择锻炼强度" :columns="exerciseStrengthColumns" :default-index="0" @cancel="exerciseStrengthShowPicker = false" @confirm="confirmExerciseStrength" />
    </van-popup>

    <van-popup v-model="exerciseFrequencyShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择锻炼频次" :columns="exerciseFrequencyColumns" :default-index="0" @cancel="exerciseStrengthShowPicker = false" @confirm="confirmExerciseFrequency" />
    </van-popup>

    <van-popup v-model="sleepStateShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择睡眠状况" :columns="sleepStateColumns" :default-index="0" @cancel="sleepStateShowPicker = false" @confirm="confirmSleepState" />
    </van-popup>

    <van-popup v-model="dietHabitShowPicker" round position="bottom">
      <van-picker class="picker" show-toolbar title="请选择饮食习惯" :columns="dietHabitColumns" :default-index="0" @cancel="dietHabitShowPicker = false" @confirm="confirmDietHabit" />
    </van-popup>

    <van-popup v-model="diagnoseDatePicker" round position="bottom">
      <van-datetime-picker v-model="diagnoseDate" :min-date="minDate" type="date" title="选择确诊日期" @change="handleTime" @confirm="confirmTime" />
    </van-popup>
  </div>
</template>

<script>
import forms from '@/mixin/Form.js'
import picker from '@/mixin/Picker.js'
import radio from '@/mixin/Picker.js'
import datetimePicker from '@/mixin/DatetimePicker.js'
export default {
  mixins: [forms, picker, radio, datetimePicker],
  data() {
    return {
      heightShowPicker: false,
      weightShowPicker: false,
      diagnoseDatePicker: false,
      exerciseWayShowPicker: false,
      exerciseStrengthShowPicker: false,
      exerciseFrequencyShowPicker: false,
      sleepStateShowPicker: false,
      dietHabitShowPicker: false,
      heightColumns: [],
      weightColumns: [],
      exerciseWayColumns: [],
      exerciseStrengthColumns: [],
      exerciseFrequencyColumns: [],
      sleepStateColumns: [],
      dietHabitColumns: [],
      height: '',
      weight: '',
      diagnoseDate: new Date(),
      minDate: new Date(2000, 0, 1),
      params: {
        height: '',
        weight: '',
        bmi: '',
        exerciseWay: '',
        exerciseStrength: '',
        exerciseFrequency: '',
        exerciseTime: '',
        sleepTime: '',
        sleepState: '',
        dietHabit: '',
        disease: {
          ownIllHistory: '',
          ownIllHistoryText: '',
          allergyHistory: '',
          allergyHistoryText: '',
          familyHistory: '',
          familyHistoryText: '',
          liverFunction: '',
          liverFunctionText: '',
          kidneyFunction: '',
          kidneyFunctionText: '',
        },
        diagnoses: [{ diagnose: '', diagnoseDate: '' }],
      },
      diagnosesList: [],
      diagnosesShow: false,
      timer: null,
      curIndex: 0,
    }
  },
  created() {
    this.initHeightColumns()
    this.initWeightColumns()
    this.initExerciseWayColumns()
    this.initExerciseStrengthColumns()
    this.initExerciseFrequencyColumns()
    this.initSleepStateColumns()
    this.initDietHabitColumns()
    if (
      this.$route.name === 'patient.archives.edit' ||
      this.$route.name === 'archives.edit'
    ) {
      this.getDoc()
    } else {
      return
    }
  },
  methods: {
    getDoc() {
      this.$axios({
        url: 'healthyDoc/queryHealthDoc',
        data: {
          patientId: this.$route.query.patientId,
        },
        elseData: {
          loading: true,
        },
      }).then((res) => {
        console.log(res)
        if (res.d) {
          this.params = res.d
          let { height, weight } = this.params
          this.height = `${height} CM`
          this.weight = `${weight} KG`
        }
      })
    },
    initHeightColumns() {
      this.heightColumns = [...Array(300)].map((k, i) => `${i + 1} CM`)
      console.log(this.heightColumns)
    },
    initWeightColumns() {
      this.weightColumns = [...Array(200)].map((k, i) => `${i + 1} KG`)
      console.log(this.weightColumns)
    },
    initExerciseWayColumns() {
      this.$axios({
        url: 'healthyDoc/queryExerciseWay',
      }).then((res) => {
        console.log(res)
        this.exerciseWayColumns = res.d.data
      })
    },
    initExerciseStrengthColumns() {
      this.$axios({
        url: 'healthyDoc/queryExerciseStrength',
      }).then((res) => {
        console.log(res)
        this.exerciseStrengthColumns = res.d.data
      })
    },
    initExerciseFrequencyColumns() {
      this.$axios({
        url: 'healthyDoc/queryExerciseFrequency',
      }).then((res) => {
        console.log(res)
        this.exerciseFrequencyColumns = res.d.data
      })
    },
    initSleepStateColumns() {
      this.$axios({
        url: 'healthyDoc/querySleepState',
      }).then((res) => {
        console.log(res)
        this.sleepStateColumns = res.d.data
      })
    },
    initDietHabitColumns() {
      this.$axios({
        url: 'healthyDoc/queryDietHabit',
      }).then((res) => {
        console.log(res)
        this.dietHabitColumns = res.d.data
      })
    },
    confirmHeight(value, index) {
      console.log(value, index)
      this.height = value
      this.params.height = index + 1
      this.heightShowPicker = false
      this.calculateBI( this.params.height,this.params.weight)
    },
    confirmWeight(value, index) {
      console.log(value, index)
      this.weight = value
      this.params.weight = index + 1
      this.weightShowPicker = false
      this.calculateBI( this.params.height,this.params.weight)
    },
    confirmExerciseWay(value, index) {
      console.log(value, index)
      this.params.exerciseWay = value
      this.exerciseWayShowPicker = false
    },
    confirmExerciseStrength(value, index) {
      this.params.exerciseStrength = value
      this.exerciseStrengthShowPicker = false
    },
    confirmExerciseFrequency(value, index) {
      this.params.exerciseFrequency = value
      this.exerciseFrequencyShowPicker = false
    },
    confirmSleepState(value, index) {
      this.params.sleepState = value
      this.sleepStateShowPicker = false
    },
    confirmDietHabit(value, index) {
      this.params.dietHabit = value
      this.dietHabitShowPicker = false
    },

    calculateBI(height, weight) {
      if (height && weight) {
        this.$axios({
          url: 'healthyDoc/calculateBI',
          type: 'post',
          data: {
            height: height,
            weight: weight,
          },
          elseData: {
            loading: true,
          },
        }).then((res) => {
          console.log(res)
          this.params.bmi = res.d.bmi
        })
      }
    },
    submitAction() {
      this.appCheckFormAction().then(() => {
        console.log(this.params)

        if (this.$route.name === 'patient.archives.add') {
          delete this.params.patientId
        }
        if (this.$route.name === 'patient.archives.edit') {
          delete this.params.patientId
        }
        if (this.$route.name === 'archives.edit') {
          this.params.patientId = this.$route.query.patientId
        }
        if (this.params.disease.ownIllHistory === 0) {
          this.params.disease.ownIllHistoryText = ''
        }
        if (this.params.disease.allergyHistory === 0) {
          this.params.disease.allergyHistoryText = ''
        }
        if (this.params.disease.familyHistory === 0) {
          this.params.disease.familyHistoryText = ''
        }
        if (this.params.disease.liverFunction === 0) {
          this.params.disease.liverFunctionText = ''
        }
        if (this.params.disease.kidneyFunction === 0) {
          this.params.disease.kidneyFunctionText = ''
        }
        this.$emit('submitForm', this.params)
      })
    },
    add() {
      if (this.params.diagnoses.length == 5) return
      this.params.diagnoses.push({
        diagnose: '',
        diagnoseDate: '',
      })
    },
    handleTime(val) {
      //   this.curIndex = this.params.diagnoses.length - 1
      console.log(val)
    },
    confirmTime(val, $event) {
      //   this.curIndex = this.params.diagnoses.length - 1
      //   // console.log(this.$.replyTimeToDay(val))
      this.diagnoseDatePicker = false
      console.log(val)
      this.params.diagnoses[this.curIndex].diagnoseDate = this.$.replyTimeToDay(val)
      console.log(this.params.diagnoses[this.curIndex].diagnoseDate)
    },
    debounce(fn, delay) {
      let timerid
      return function (...args) {
        if (timerid) {
          clearTimeout(timerid)
          timerid = null
        }
        timerid = setTimeout(() => {
          fn(...args)
          timerid = null
        }, delay)
      }
    },
    getDiagnosesList(val) {
      this.$axios({
        url: 'healthyDoc/queryDisease',
        data: {
          disease: val,
        },
      }).then((res) => {
        console.log(res)
        this.diagnosesList = [...res.d]
      })
    },
    inputdiagnose(val) {
      console.log(val)
      //   console.log()
      if (!val) {
        this.diagnosesShow = false
        return
      }
      //   this.curIndex = this.params.diagnoses.length - 1
      clearTimeout(this.timer)
      this.diagnosesShow = true
      this.timer = setTimeout(() => {
        this.getDiagnosesList(val)
      }, 300)
    },
    handleFocus(i) {
      console.log(i)
      this.curIndex = i
      console.log('改curindex', this.curIndex)
    },
    select(item) {
      console.log(item.name)
    //   this.params.diagnoses[this.curIndex].diagnose = item.name
      this.$set(this.params.diagnoses[this.curIndex],'diagnose',item.name)
      this.diagnosesShow = false
    },
    remove(i) {
      console.log('=======', i)
      this.curIndex = i
      this.params.diagnoses.splice(this.curIndex, 1)
      console.log('=======', this.params.diagnoses)
    },
    handleChange(val) {
      console.log(val)
    },
    handleDiagnoseDatePicker(i) {
      this.curIndex = i
      console.log('改curindex', this.curIndex)
      this.diagnoseDatePicker = true
    },
    handleBlur(){
        setTimeout(()=>{
           this.diagnosesShow = false
        },1000)
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
}
/deep/.van-field__control:disabled {
  color: #333 !important;
}
.form {
  flex: 1;
  overflow: auto;
  .van-cell-group--inset {
    margin: 8px;
  }

  /deep/.van-cell-group {
    margin: 8px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding: 12px 16px;
      em {
        color: #da5756;
        margin-right: 8px;
      }
    }
    .van-cell::after {
      border-color: #e5e5e5;
    }
    .van-cell {
      align-items: center;
      padding: 3px 16px;
      .van-field__label:first-of-type {
        margin-top: 0px;
      }
      .van-field__label {
        flex: 1;
        margin-top: 8px;
        margin-left: 0;
        font-size: 14px;
        line-height: 3;
        width: 100%;
      }

      .van-field__value {
        font-size: 14px;
        .van-field__control {
          text-align: right;
        }
      }
      .extra {
        font-size: 14px;
        margin-left: 12px;
      }

      .select {
        align-self: flex-end;
        .van-cell__right-icon {
          margin-left: 4px;
          color: #969799;
          position: absolute;
          right: 7px;
          bottom: 10px;
          display: flex;
          align-items: center;
        }
        .van-field__value {
          flex: 1;
          position: relative;
          padding: 9px 12px;
        }
      }
    }
    .radiogroup {
      display: flex;
      justify-content: space-between;
      padding: 11px 0px;

      .con {
        display: flex;

        .van-radio {
          margin-left: 45px;
          margin-right: 0;
          .van-radio__label {
            color: #999;
          }
          .van-icon-success:before {
            color: #fff;
          }
        }
        .van-radio:first-child {
          margin-left: 0px;
        }
      }
    }

    .textarea {
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      width: auto;
      border-radius: 8px;
      color: #333 !important;
      padding: 10px !important;
      .van-field__control {
        text-align: left !important;
      }
    }
    .item {
      margin: 0 16px;
      .van-cell {
        padding: 3px 16px;
        align-items: center;
        .van-field__label:first-of-type {
          margin-top: 0px;
        }
        .van-field__control {
          justify-content: flex-end;
        }
      }
    }
    .item.bt {
      position: relative;
    }
    .item.bt::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      transform: scaleY(-0.5);
    }
    .disease:last-child {
      margin-bottom: 10px;
    }
    .disease {
      position: relative;
      & > div {
        margin: 12px 16px 0px 16px;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        overflow: hidden;
        .remove {
          background: red;
          color: #fff;
          padding: 2px;
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -6px;
          z-index: 999;
          border: 10px solid transparent;
          background-clip: padding-box;
        }

        .van-cell {
          display: flex;
        }
        .van-field__value {
          flex: 1;
        }
        .van-field__label {
          width: 75px;
          flex: none;
        }
      }
    }
    .add {
      font-size: 14px;
      font-weight: 400;
      color: #1ab370;
      text-align: center;
      margin: 24px auto 12px;
    }
  }
  .btn-group {
    margin: 24px 16px;
    .btn-order {
      width: 100%;
    }
  }
}

.diagnoses {
  background: #fff;
  padding: 0 24px;
  box-shadow: 0px -4px 4px 0px rgba(102, 102, 102, 0.12);
  z-index: 99;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    white-space: nowrap;
    margin: 0 20px;
    line-height: 60px;
  }
}

/deep/.picker {
  .van-picker__columns {
    margin-top: 10px;
  }
  .van-picker__toolbar {
  }
}
</style>
