/*
 * @Author: 张博洋
 * @Date: 2021-07-22 11:22:30
 * @LastEditTime: 2022-03-03 11:04:37
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/mixin/Button copy.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  DatetimePicker
} from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[DatetimePicker.name] = DatetimePicker;
    return obj;
  })(),
}
