/*
 * @Author: 张博洋
 * @Date: 2022-02-21 17:50:17
 * @LastEditTime: 2022-02-21 17:50:53
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/mixin/Picker.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  //tab
  Picker
} from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[Picker.name] = Picker
    return obj;
  })(),
}
